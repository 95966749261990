import React, { useLayoutEffect } from 'react'
import styled from 'styled-components'
import { Parallax, useController } from 'react-scroll-parallax'
import ConstructionBuilding from '../assets/images/construction-building.jpg'
import ScrollAnimationWrapper from '../components/ScrollAnimationWrapper'
import PageTitle from '../components/PageTitle'

const AboutContainer = styled.div`
  display: grid;
  grid-template-columns: auto 40%;
  position: relative;
  overflow: hidden;

  .about-content {
    padding: 10%;
    background-color: var(--light-color);
    align-self: center;
    height: 100%;

    h1 {
      color: var(--primary-color);
    }

    p {
      color: var(--dark-color);
      text-indent: 30px;
      font-size: 20px;
    }
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image-container {
    position: absolute;
    height: 100%;
    width: 40%;
    right: 0;
    overflow: hidden;
  }

  .image-container .parallax-inner {
    height: 100%;
  }

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;

    .image-container {
      position: unset;
      width: 100%;
      height: 370px;
    }

    .image-container .parallax-inner {
      height: 210%;
    }

    .image-container img {
      transform: scale(1.2);
      object-position: 0% 5%;
    }
  }

  @media screen and (max-width: 400px) {
    .image-container {
      height: 270px;
    }

    .image-container .parallax-inner {
      height: 170%;
    }

    .image-container img {
      transform: scale(1.2);
    }
  }
`

const About = () => {
  const parallaxController = useController().parallaxController
  useLayoutEffect(() => {
    const handler = () => {
      parallaxController.update()
    }
    handler()
    window.addEventListener('load', handler)
    return window.removeEventListener('load', handler)
  }, [parallaxController])
  return (
    <AboutContainer id="about-container">
      <div className={'about-content'}>
        <PageTitle
          title="About us"
          primary
          lineStyle={{
            marginLeft: 'unset',
            marginRight: 'unset'
          }}
        />
        <ScrollAnimationWrapper delay={200}>
          <p>
            M/s dhiara group is a leading engineering and construction firm
            based in Mumbai. The firm has been building large and complex
            structures since the last 34 years. The core strength of m/s dhiara
            group is to build large commercial and residential projects within
            the stipulated time period, with good quality. The company has
            created rich marketing on its own and is on an experiential growth
            path. M/s dhiara group are partnership firm and is managed by mr.
            Dinesh senghani & mr.Narendra rangani(patel). Their entrepreneurial
            spirits and the urge to diversify took them to found a construction
            company.
          </p>
        </ScrollAnimationWrapper>
        <br />
        <ScrollAnimationWrapper>
          <p>
            Dhiara group believe in investing for the future world-class quality
            and innovative technology that creates trends through value
            engineering. Dhiara group has been executing some of the most
            exciting and challenging projects in Mumbai. Dhiara venture's
            current portfolio consists of 6 residential projects and a
            residential project at the outskirts of Mumbai as a developer. The
            order book of dhiara group currently stands at over 40 crores.
            Dhiara group assure the biggest standard quality, speed & justice to
            work.
          </p>
        </ScrollAnimationWrapper>
      </div>
      <Parallax className={'image-container'} y={[-40, 40]}>
        <img src={ConstructionBuilding} alt="About us building" />
      </Parallax>
    </AboutContainer>
  )
}

export default About
