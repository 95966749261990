export const getTeamMembersList = () => [
  {
    name: 'Lalmani H Yadav',
    qualification: 'Diploma in construction engineer',
    designation: 'Project Engineer',
    experience: '18 Years',
    present_involvement: [
      'Supervising day to day execution of work so as to get the work executed as per the given specifications and maintaining the quality standard,ordering & supervising the usage of various material issued by clients',
      'Monitoring the project against the planned schedule,reporting the deviations,co-ordinating with clients&various agencies in case of major devaitions so as to reschedule plans',
      'Preparation of daily reports & generation of various periodic report'
    ]
  },
  {
    name: 'Jigar Makani',
    qualification: 'Diploma & Degree in civil engineering',
    designation: 'Site engineer',
    experience: '4 Years',
    present_involvement: [
      'Co-ordinating between various’s agency’s staff to maitain proper flow of project.',
      'Assiting in preprations of project flow chart ,and progress chart’s in co-ordination with respective consulting  agencies.',
      'Checking the certified bills of all material’s vendor’s',
      'Supervising day to day executions of work so as to get the work executed as per given spciffications & maintaining the quality standards.'
    ]
  },
  {
    name: 'Kishan Sojitra',
    qualification: 'Diploma & Degree in civil engineering',
    designation: 'Junior Site engineer',
    experience: '2 Years',
    present_involvement: [
      'Handling the labour team since kishan joined our company, his responsibilty involve’s quantity estimation of all material required at site before execution’s',
      'Bbs expert in steel department , quality incharge of all material recievable at site.'
    ]
  },
  {
    name: 'Shobha',
    qualification: 'Diploma in computer & applications',
    designation: 'Accountant',
    experience: '6 Years',
    present_involvement: [
      'Reconciling general ledger accounts.',
      'Preparing&analyzing fianancial statement,reviewing.'
    ]
  },
  {
    name: 'Avinash Jadhav',
    qualification: 'Diploma & Degree in civil engineering',
    designation: 'Structural designer',
    experience: '12 Years',
    present_involvement: [
      'In-house structure desgining',
      'Also work at S.V. Patel and Associates as senior designing engineer'
    ]
  },
  {
    name: 'Reena borade',
    qualification: 'B.Com',
    designation: 'Accountant',
    experience: '6 Years',
    present_involvement: [
      'Mainatining acoount book of company under indian law',
      'Managing cash flow'
    ]
  }
]
