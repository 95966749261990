const LogoProvider = ({ light }) => {
  const upper_color = '#FF3300'
  const lower_color = light ? '#fff4f1' : '#370C00'
  return (
    <>
      <svg
        width="100"
        height="127"
        viewBox="0 0 100 127"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="upper-half-logo"
          d="M42.5653 57.935L37.2752 61.7137V33.7518L42.5653 28.4617V57.935Z"
          fill={upper_color}
          stroke={upper_color}
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          className="upper-half-logo"
          d="M51.634 54.1566L48.6111 55.668V16.3703L53.9011 22.4161V55.668L51.634 54.1566Z"
          fill={upper_color}
          stroke={upper_color}
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          className="upper-half-logo"
          d="M65.2368 39.7978L60.7025 34.5077V59.4466L65.2368 62.4695V39.7978Z"
          fill={upper_color}
          stroke={upper_color}
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          className="upper-half-logo"
          d="M49.0644 19.3931V0.5"
          stroke={upper_color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          className="upper-half-logo"
          d="M63.725 83.6298L50.8777 91.9428L40.2975 85.897L44.0762 81.3626L50.8777 85.1412L59.1907 79.8512V70.7825L50.8777 65.4924L44.0762 70.0267L40.2975 65.4924L50.8777 58.6908L63.725 67.7596V83.6298Z"
          fill={upper_color}
          stroke={upper_color}
          stroke-linejoin="round"
        />
        <path
          className="upper-half-logo"
          d="M38.1819 84.5366V67.155L42.7163 72.445V78.4908L38.1819 84.5366Z"
          fill={upper_color}
          stroke={upper_color}
          stroke-linejoin="round"
        />
        <path
          className="lower-half-logo"
          d="M36.519 111.592C20.1954 114.01 6.03816 122.172 1 125.95C15.5099 116.882 39.7938 116.63 50.1221 117.637V109.324H47.8549L43.3206 105.546V94.21L36.519 89.6756V107.057L46.3435 115.37L36.519 111.592Z"
          fill={lower_color}
          stroke={lower_color}
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          className="lower-half-logo"
          d="M63.8251 111.592C80.1488 114.01 94.306 122.172 99.3441 125.95C84.8342 116.882 60.5503 116.63 50.2221 117.637V109.324H52.4892L57.0236 105.546V94.21L63.8251 89.6756V107.057L54.0007 115.37L63.8251 111.592Z"
          fill={lower_color}
          stroke={lower_color}
          stroke-width="0.5"
          stroke-linejoin="round"
        />
      </svg>
    </>
  )
}

LogoProvider.defaultProps = {
  light: false
}

export default LogoProvider
