import 'animate.css/animate.min.css'
import { ParallaxProvider } from 'react-scroll-parallax'
import About from './About'
import Home from './Home'
import Projects from './Projects'
import VizSensor from 'react-visibility-sensor'
import { useCallback } from 'react'
import Teams from './Teams'
import ContactUs from './ContactUs'

export const Main = ({ setActivePage }) => {
  const VizSensorWrapper = useCallback(
    ({ children, page, ...props }) => (
      <VizSensor
        {...props}
        partialVisibility
        onChange={isVisible => {
          // console.log(isVisible, 'isVisible', page)
          if (isVisible) setActivePage(page)
        }}
      >
        {children}
      </VizSensor>
    ),
    [setActivePage]
  )
  return (
    <>
      <ParallaxProvider>
        <VizSensorWrapper offset={{ top: 150 }} page="home">
          <Home />
        </VizSensorWrapper>
        <VizSensorWrapper offset={{ top: 150, bottom: 50 }} page="about">
          <About />
        </VizSensorWrapper>
        <VizSensorWrapper offset={{ top: 150, bottom: 50 }} page="project">
          <Projects />
        </VizSensorWrapper>
        <VizSensorWrapper offset={{ top: 150, bottom: 50 }} page="teams">
          <Teams />
        </VizSensorWrapper>
        <VizSensorWrapper offset={{ bottom: 50 }} page="contact">
          <ContactUs />
        </VizSensorWrapper>
      </ParallaxProvider>
    </>
  )
}
