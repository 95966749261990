import React from 'react'
import styled from 'styled-components'
import ScrollAnimationWrapper from './ScrollAnimationWrapper'

const PageTitleWrapper = styled.div`
  .horizontal-line {
    margin: 0 3%;
    margin-bottom: 30px;
    margin-top: 15px;
    height: 2px;
    background-image: linear-gradient(
      95deg,
      var(--light-color),
      var(--light-color-transperent)
    );
  }

  .horizontal-line.primary {
    background-image: linear-gradient(
      95deg,
      var(--primary-color),
      var(--primary-color-light)
    );
  }

  .horizontal-line.dark {
    background-image: linear-gradient(
      95deg,
      var(--dark-color),
      var(--light-color-transperent)
    );
  }
`

const PageTitle = ({ title, primary, dark, lineStyle }) => {
  return (
    <PageTitleWrapper>
      <ScrollAnimationWrapper duration={1}>
        <h1>{title}</h1>
      </ScrollAnimationWrapper>
      <ScrollAnimationWrapper delay={150} animateIn="animate__fadeInLeft">
        <div
          className={`horizontal-line ${
            primary ? 'primary' : dark ? 'dark' : ''
          }`}
          style={lineStyle}
        />
      </ScrollAnimationWrapper>
    </PageTitleWrapper>
  )
}

PageTitle.default = {
  lineStyle: {},
  primary: false,
  title: 'Page title:'
}

export default PageTitle
