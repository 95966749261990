import NarendraRangani from '../assets/images/member_images/narendra_rangani.jpeg'
import DineshBhanushali from '../assets/images/member_images/dinesh_bhanushali.jpeg'
import SurendraRangani from '../assets/images/member_images/surendra_rangani_cropped.jpeg'
import AtulThakkar from '../assets/images/member_images/atul_thakkar.jpeg'
import DineshSenghani from '../assets/images/member_images/dinesh_senghani_cropped.jpg'
import NiteshRangani from '../assets/images/member_images/nitesh_rangani_cropped.jpeg'
import KashyapRangani from '../assets/images/member_images/kashyap_rangani.jpeg'

export const getManagers = () => [
  {
    name: 'Narendra Rangani',
    details:
      'Narendra Rangani(Patel) is the son of Karsan Patel. Narendra Rangani(Patel) took over his father ‘s company long back from 2002.Since 2002 he is handling and developed his father business next to impossible level .Before taking over his father company Mr. Narendra Rangani(Patel) was working with hiranandani group over 15 years and he was head incharge of overall powai. Narendra Rangani(Patel) aged 50 and His job is to look over all execution work and completing work before said periods.',
    image: NarendraRangani
  },
  {
    name: 'Dinesh Patel',
    details:
      "Dinesh Senghani is the son of a Hirji Senghani. Dinesh Senghani take over his father's company from 1995. since 1995 to till date he is handling company on behalf of Hirji Senghani. He established the business from small labour contractor to material contractor and now a developer. Dinesh Senghani aged 49 years, His job profiles include Co-ordinating between clinent, monitoring the project, Planning of various work activities of project.",
    image: DineshSenghani
  },
  {
    name: 'Dinesh Bhanushali',
    details:
      'Dinesh Bhanushali have an in-depth understanding of all company products and services, and the skills and industry knowledge required to sell them. He also prepare marketing budgets, conduct research and analyze trends, lead the sales team, and perform sales forecasts. He have a work experience of more than 15 years.',
    image: DineshBhanushali
  },
  {
    name: 'Atul Thakkar',
    details:
      'A self-driven visionary leader, an entrepreneur, with over 15 years of hands on experience in the Real Estate has developed a strong network of developers. His quest to remain differentiated has compelled him to enroll and complete the Real estate Management Program. His creativity and passion enables him to set a vision beyond the conventional horizons of natural progression.',
    image: AtulThakkar
  },
  {
    name: 'Surendra Rangani',
    details:
      'Mr. Surendra Rangani (Patel) is son of Mr. Velji patel. Mr. Surendra Rangani is respected and honorable RCC Consultant in Ghatkopar, He has focused on both consulting firm and developing firm from past 20 years. Mr. Surendra Rangani(Patel) has massive experince in construction firm. He has only one motive "Better home with better construction". Mr. Surendra Rangani\'s(Patel) Role in M/S Dhiara group is dealing projects and consulting projects.',
    image: SurendraRangani
  },
  {
    name: 'Nitesh Rangani',
    details:
      'Inspect facilities and analyze operational data,Suggest process and technical design changes to improve performance and efficiency,Compile estimates for technical and material requirements for project development,Prepare proposal documents and presentations,Create work schedules and adjust as needed to meet project deadlines,Maintain detailed documentation of all site engineering activities, Collaborate with management to align activities with desired business outcomes.',
    image: NiteshRangani
  },
  {
    name: 'Kashyap Rangani',
    details:
      'Co-ordinating between clients and various consulting agencies,execution agencies,supplier etc for the efficient excution of project. Assiting in preprations of various tenders in coordination with respective consulting agencies. Checking the certified bills of various agencies against the tendered boq & release payment. Supervising day to day executions of work so as to get the worj executed as per given spciffications & maintaining the quality standards.',
    image: KashyapRangani
  }
]
