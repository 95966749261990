import React from 'react'
import ConstructionVideo from '../assets/videos/file.mp4'
import ConstructionImage from '../assets/images/background-image.webp'
import styled from 'styled-components'

const BackgroundVideoContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -10;

  .background-video {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }

  .background-image {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
`

const BackgroundVideo = () => {
  return (
    <BackgroundVideoContainer>
      <video
        className="background-video"
        autoPlay
        loop
        muted
        preload="metadata"
      >
        <source src={ConstructionVideo} type="video/mp4" />
      </video>
      <img
        className="background-image"
        src={ConstructionImage}
        alt="Construction"
      />
    </BackgroundVideoContainer>
  )
}

export default BackgroundVideo
