import React, { useMemo } from 'react'
import styled from 'styled-components'
import ProjectCard from '../components/ProjectCard'
import ScrollAnimationWrapper from '../components/ScrollAnimationWrapper'
import OngoingProjects from '../assets/json/ongoing_projects.json'
import PageTitle from '../components/PageTitle'
import PreviousProjectsTable from '../components/PreviousProjectsTable'

const ProjectsContainer = styled.div`
  .current-projects-container {
    padding: 40px 10px;
    background: var(--dark-color);
    color: var(--light-color);
  }

  h1,
  .existing-projects-list {
    padding-left: 3%;
    padding-right: 3%;
  }

  .current-projects-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .existing-projects-container {
    overflow: hidden;
    padding: 40px 10px;
    background: var(--light-color);
    color: var(--primary-color);
  }

  @media (max-width: 468px) {
    .current-projects-list {
      padding: 0 3%;
    }

    .animated {
      width: 100%;
    }
  }
`
const Projects = () => {
  const ProjectList = useMemo(() => OngoingProjects, [])

  return (
    <ProjectsContainer id="project-container">
      <div className="current-projects-container">
        <PageTitle title="Ongoing Projects: " />
        <div className={'current-projects-list'}>
          {ProjectList.map((project, index) => {
            const desired_count = Math.floor(window.innerWidth / 335) // 335 is approx width of the card
            const duration = (index % desired_count) * 150
            return (
              <ScrollAnimationWrapper
                className="project-card-animation-wrapper"
                key={`project-details-${index}`}
                delay={duration}
                style={{
                  zIndex: index
                }}
              >
                <ProjectCard
                  className="current-project-card"
                  name={project.name}
                  location={project.location}
                  client={project.client}
                  architect={project.architect}
                  rcc_consultant={project.rcc_consultant}
                  cost={project.cost}
                  floors_upper={project.floors_upper}
                  floors_lower={project.floors_lower}
                  status={project.status}
                  type={project.type}
                />
              </ScrollAnimationWrapper>
            )
          })}
        </div>
      </div>
      <div className="existing-projects-container">
        <PageTitle title="Last 10 years Projects: " primary />
        <ScrollAnimationWrapper animateIn="animate__fadeInRight">
          <div className="existing-projects-list">
            <PreviousProjectsTable />
          </div>
        </ScrollAnimationWrapper>
      </div>
    </ProjectsContainer>
  )
}

export default Projects
