import React from 'react'
import styled from 'styled-components'
import { FaAngleDoubleDown } from 'react-icons/fa'
import { ChangeHash } from '../components/Navbar'

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  width: 100%;

  &::after {
    content: '';
    width: inherit;
    height: inherit;
    position: absolute;
    background-image: radial-gradient(
        circle at center center,
        rgba(221, 203, 18, 0.3),
        var(--dark-color)
      ),
      repeating-linear-gradient(
        135deg,
        var(--dark-color) 0px,
        var(--dark-color) 1px,
        transparent 1px,
        transparent 4px
      ),
      repeating-linear-gradient(
        45deg,
        var(--primary-color) 0px,
        var(--primary-color) 5px,
        transparent 5px,
        transparent 6px
      ),
      linear-gradient(90deg, var(--dark-color), var(--dark-color));
    z-index: -1;
    opacity: 0.25;
  }

  .tag-line-icon-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 760px) {
      width: 65%;
    }
  }

  .down-icon,
  .tag-line {
    color: var(--light-color);
    font-size: 4rem;
    text-align: center;

    @media screen and (max-width: 870px) {
      font-size: 3.5rem;
    }

    @media screen and (min-width: 1440px) {
      font-size: 5rem;
    }

    @media screen and (min-width: 1868px) {
      font-size: 6rem;
    }
  }

  .down-icon {
    cursor: pointer;
    margin-top: 10px;
    animation: upDown 1.5s ease-in-out infinite;
  }

  @keyframes upDown {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, 15px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
`

const Home = () => {
  const DownArrowClickHandler = () => {
    ChangeHash('about')
  }
  return (
    <HomeContainer id="home-container">
      <div className="tag-line-icon-container">
        <h1 className={'tag-line'}>We are building the future of India</h1>
        <span className={'down-icon'} onClick={DownArrowClickHandler}>
          <FaAngleDoubleDown className={'down-icon-svg'} />
        </span>
      </div>
    </HomeContainer>
  )
}

export default Home
