import React from 'react'
import styled from 'styled-components'
import { FaRegBuilding, FaRupeeSign, FaTools, FaUser } from 'react-icons/fa'

const ProjectCardContainer = styled.div`
  width: fit-content;
  padding: 20px;
  background: var(--light-color);
  color: var(--primary-color);
  border-radius: 7px;
  box-shadow: #000000e0 6px 6px 50px -18px;
  margin-bottom: 20px;
  margin-right: 10px;
  transition: all 0.2s ease-in-out;

  @media (max-width: 468px) {
    width: 100%;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: #000000e0 7px 7px 50px -15px;
  }

  .title {
    color: var(--dark-color);
    font-weight: bold;
    margin-right: 10px;
  }

  .name-location-container {
    margin-bottom: 15px;

    .location {
      display: block;
      font-size: 13px;
      margin-top: -5px;
    }
  }

  .team-info {
    --border-color: var(--primary-color-light);
    margin-top: 8px;
    margin-bottom: 20px;
    table {
      width: 100%;
      border-collapse: collapse;
      border-radius: 1px;
      border-style: none;
      box-shadow: 0 0 0 0.7px var(--border-color);
    }

    td,
    th {
      text-align: center;
      padding: 7px 10px;
      border-bottom: 1px solid var(--border-color);
    }

    tr:last-child td {
      border: none;
    }
  }

  .floors {
    display: flex;
  }

  .project-status-container {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;

    .status-child:first-child {
      margin-right: 5px;
    }

    .in-progress {
      color: var(--red-color);
    }

    .completed {
      color: var(--green-color);
    }
  }

  .fa-icon {
    font-size: 12px;
  }
`

const ProjectCard = ({
  className,
  name,
  location,
  client,
  architect,
  rcc_consultant,
  cost,
  floors_upper,
  floors_lower,
  status,
  type
}) => {
  return (
    <ProjectCardContainer className={className}>
      <div className={'name-location-container'}>
        <h3 className="name title">{name}</h3>
        <span className="location">{location}</span>
      </div>
      <div className="client-container">
        <span className="title">
          <FaUser className={'fa-icon'} /> Client:{' '}
        </span>
        <span>{client}</span>
      </div>
      {type && (
        <div>
          <span className="title">Type:</span>
          <span>{type}</span>
        </div>
      )}
      <div className="team-info">
        <table>
          <thead>
            <tr>
              <th className="title">Role</th>
              <th className="title">Team</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Architect/Consultant</td>
              <td>{architect}</td>
            </tr>
            <tr>
              <td>RCC Consultant</td>
              <td>{rcc_consultant}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <span className="floors">
        <span className="title">
          <FaRegBuilding className="fa-icon" /> Floors:
        </span>{' '}
        <span>
          {floors_upper}
          <br />
          {floors_lower}
        </span>
      </span>
      <div className="project-status-container">
        <span className="status-child">
          <span className="title">
            <FaRupeeSign className="fa-icon" />
            Cost:
          </span>{' '}
          {cost}
        </span>
        <span className="status-child">
          <span className="title">
            <FaTools className="fa-icon" /> Status:
          </span>
          <span
            className={
              String(status).toLowerCase() === 'completed'
                ? 'completed'
                : 'in-progress'
            }
          >
            {status}
          </span>
        </span>
      </div>
    </ProjectCardContainer>
  )
}

ProjectCard.defaultProps = {
  className: ''
}

export default ProjectCard
