import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import PageTitle from '../components/PageTitle'
import { FaEnvelopeOpenText as Email, FaWhatsapp } from 'react-icons/fa'
import ScrollAnimationWrapper from '../components/ScrollAnimationWrapper'

const ContactUsWrapper = styled.section`
  padding: 25px 0;
  border-top: 3px solid var(--primary-color);
  background-color: var(--light-color);
  color: var(--dark-color);

  h1 {
    text-align: center;
  }

  .form-container {
    display: flex;
    justify-content: center;
  }

  form {
    position: relative;
    max-width: 43%;
    width: 43%;
    margin: 0 3%;

    .horizontal-group {
      display: flex;
      justify-content: space-between;
    }

    .form-group {
      margin-bottom: 15px;
    }

    .form-group:first-child {
      margin-right: 20px;
    }

    label {
      display: block;
      margin-bottom: 5px;
    }

    input,
    textarea {
      padding: 7px 17px;
      background-color: var(--dark-color-transperent);
      border: none;
      outline: none;
      border-radius: 2.5px;
      border-bottom: 3px solid;
      border-bottom-color: var(--dark-color-light);
    }

    input:focus,
    textarea:focus {
      border-radius: 2.5px;
      background-color: var(--dark-color-light);
      border-bottom-color: var(--dark-color);
      border-bottom-height: 1px;
    }

    textarea {
      width: 100%;
      resize: none;
    }

    button {
      padding: 7px 17px;
      border-color: var(--dark-color-light);
      color: var(--dark-color);
      background: transparent;
      border-radius: 2.5px;
      font-size: 14px;
      cursor: pointer;
      margin-bottom: 15px;

      .icon {
        vertical-align: middle;
        margin-right: 3px;
      }

      transition: background 0.4s, border 0.2s, color 0.2s ease-out;

      &:hover {
        background: var(--dark-color);
        border-color: var(--dark-color);
        color: var(--light-color);
      }
    }

    .error {
      display: block;
      opacity: 0;
      color: var(--red-color);
      font-size: 10px;
      animation-duration: 0.3s;
    }
  }

  @media screen and (max-width: 970px) {
    .form-container {
      form {
        max-width: 90%;
        width: 70%;
      }
    }
  }

  @media screen and (max-width: 610px) {
    .form-container {
      form {
        max-width: 90%;
        width: 80%;
      }
    }
  }

  @media screen and (max-width: 570px) {
    .form-container {
      form {
        max-width: 90%;
        width: 90%;
      }
    }
  }

  @media screen and (max-width: 470px) {
    .form-container form {
      .horizontal-group {
        flex-direction: column;
        align-items: center;
      }

      .form-group:first-child {
        margin-right: unset;
      }

      .form-group {
        width: 100%;

        input {
          width: 100%;
        }
      }

      button {
        width: 100%;
      }
    }
  }
`

const ContactUs = () => {
  const [firstName, setFirstName] = useState('')
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastName, setLastName] = useState('')
  const [lastNameError, setLastNameError] = useState(false)
  const [description, setDescription] = useState('')
  const [descriptionError, setDescriptionError] = useState(false)
  const nameValidator = useMemo(
    () => /[`~!@#$%^&*()[\],<.>/?|\\}{;:'"}_\-+=]/g,
    []
  )
  const descriptionValidator = useMemo(() => /[`~^{}\\|;<>]/g, [])
  const handleFirstNameChange = e => {
    const value = String(e.target.value).replaceAll(nameValidator, '')
    if (value.length <= 0) {
      setFirstNameError(true)
    } else {
      setFirstNameError(false)
    }
    setFirstName(value)
  }

  const handleLastNameChange = e => {
    const value = String(e.target.value).replaceAll(nameValidator, '')
    if (value.length <= 0) {
      setLastNameError(true)
    } else {
      setLastNameError(false)
    }
    setLastName(value)
  }

  const handleDescriptionChange = e => {
    const value = String(e.target.value).replaceAll(descriptionValidator, '')
    if (value.length <= 0) {
      setDescriptionError(true)
    } else {
      setDescriptionError(false)
    }
    setDescription(value)
  }

  const handleContactClick = (e, _for) => {
    e.preventDefault()
    let valid = true
    if ((firstName === '') | (firstName === undefined) || firstName === null) {
      valid = false
      setFirstNameError(true)
    }
    if ((lastName === '') | (lastName === undefined) || lastName === null) {
      setLastNameError(true)
      valid = false
    }
    if (
      (description === '') | (description === undefined) ||
      description === null
    ) {
      valid = false
      setDescriptionError(true)
    }
    if (!valid) return
    const body = encodeURIComponent(
      `Hi Dhiara Group,\n\nI'm ${firstName} ${lastName}.\nMy Concern: ${description}`
    )
    let href_url
    if (_for === 'email') {
      // open email app
      const subject = encodeURIComponent(
        `Query by ${firstName.charAt(0).toUpperCase() + firstName.slice(1)}`
      )
      href_url = `mailto:construction@dhiaragroup.com?subject=${subject}&body=${body}`
    } else if (_for === 'whatsapp') {
      // open whatsapp app
      href_url = `https://wa.me/+919930908030/?text=${body}`
    }
    window.open(href_url, '_blank')
  }

  return (
    <ContactUsWrapper id="contact-container">
      <PageTitle title="Contact us" dark />
      <ScrollAnimationWrapper>
        <div className="form-container">
          <form>
            <div className="horizontal-group">
              <div className="form-group">
                <label>First name: </label>
                <input
                  type="text"
                  value={firstName}
                  onChange={handleFirstNameChange}
                  placeholder="Alice"
                />
                <span
                  className={`error animate__animated ${
                    firstNameError ? 'animate__fadeIn' : 'animate__fadeOut'
                  }`}
                >
                  Can not be empty
                </span>
              </div>
              <div className="form-group">
                <label>Last name: </label>
                <input
                  type="text"
                  value={lastName}
                  onChange={handleLastNameChange}
                  placeholder="Bob"
                />
                <span
                  className={`error animate__animated ${
                    lastNameError ? 'animate__fadeIn' : 'animate__fadeOut'
                  }`}
                >
                  Can not be empty
                </span>
              </div>
            </div>
            <div className="form-group">
              <label>Reason for contact: </label>
              <textarea
                rows={5}
                type="text"
                value={description}
                onChange={handleDescriptionChange}
                placeholder="Tell us about your concern here, and we will get back to you with answer as soon as possible"
              />
              <span
                className={`error animate__animated ${
                  descriptionError ? 'animate__fadeIn' : 'animate__fadeOut'
                }`}
              >
                Can not be empty
              </span>
            </div>
            <div className="horizontal-group">
              <button onClick={e => handleContactClick(e, 'email')}>
                <span className="icon">
                  <Email />
                </span>{' '}
                Send email
              </button>
              <button onClick={e => handleContactClick(e, 'whatsapp')}>
                <span className="icon">
                  <FaWhatsapp />
                </span>{' '}
                Send whatsapp
              </button>
            </div>
          </form>
        </div>
      </ScrollAnimationWrapper>
    </ContactUsWrapper>
  )
}

export default ContactUs
