import React from 'react'
import styled from 'styled-components'
import ScrollAnimationWrapper from './ScrollAnimationWrapper'

const ManagersDetailsWrapper = styled.div`
  max-width: 70%;
  margin-bottom: 30px;
  .manager-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-self: center;

    &:hover {
      .name-image-container {
        img {
          transform: scale(1.1);
        }
      }
    }

    .name-image-container {
      margin-right: 10%;

      .image-container {
        width: 200px;
        border-radius: 50%;
        overflow: hidden;
      }

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        transition: transform 0.6s ease-out;
      }
    }

    .details-container {
      h3 {
        margin-bottom: 3px;
        font-size: 20px;
      }
      text-align: justify;
    }
  }

  @media screen and (max-width: 990px) {
    max-width: 80%;
  }

  @media screen and (max-width: 716px) {
    max-width: 90%;
  }

  @media screen and (max-width: 600px) {
    .manager-container {
      flex-direction: column;

      .name-image-container {
        margin-right: unset;
      }

      .details-container {
        h3 {
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
`

const ManagersDetails = ({ name, image, details }) => {
  return (
    <ManagersDetailsWrapper>
      <ScrollAnimationWrapper>
        <div className="manager-container">
          <div className="name-image-container">
            <div className="image-container">
              <img src={image} alt={`${name}`} />
            </div>
          </div>

          <div className="details-container">
            <h3>{name}</h3>
            <p>{details}</p>
          </div>
        </div>
      </ScrollAnimationWrapper>
    </ManagersDetailsWrapper>
  )
}

export default ManagersDetails
