import React, { useMemo } from 'react'
import styled from 'styled-components'
import ManagersDetails from '../components/ManagersDetails'
import PageTitle from '../components/PageTitle'
import ScrollAnimationWrapper from '../components/ScrollAnimationWrapper'
import TeamMemberDetails from '../components/TeamMemberDetails'
import { getTeamMembersList } from '../utils/getTeamMembersList'
import { getManagers } from '../utils/getManagerMembersList'

const TeamStrengthWrapper = styled.div`
  padding: 30px 0;
  background-color: var(--dark-color);
  color: var(--light-color);

  h1 {
    margin-left: 3%;
  }

  .counter-section {
    display: flex;
    flex-direction: columns;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .count-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 135px;
    margin-right: 10px;

    .counter {
      font-size: 50px;
      margin-bottom: 5px;
    }

    .counter-name {
      font-size: 15px;
      font-weight: bold;
    }
  }
`

const ManagerMembersWrapper = styled.div`
  padding: 30px 3%;
  background-color: var(--light-color);
  color: var(--dark-color);
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    text-align: center;
    margin-bottom: 35px;
  }
`

const TeamMembersWrapper = styled.div`
  padding: 30px 3%;
  background-color: var(--light-color);
  color: var(--dark-color);

  h2 {
    text-align: center;
    margin-bottom: 35px;
  }

  .members-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
`

const Teams = () => {
  const teamMembers = useMemo(getTeamMembersList, [])

  const managerMember = useMemo(getManagers, [])
  return (
    <section id="teams-container">
      <TeamStrengthWrapper>
        <PageTitle title="Teams" />
        <div className="counter-section">
          <ScrollAnimationWrapper>
            <div className="count-container">
              <span className="counter">04</span>
              <span className="counter-name">Engineers</span>
            </div>
          </ScrollAnimationWrapper>
          <ScrollAnimationWrapper delay={50}>
            <div className="count-container">
              <span className="counter">15</span>
              <span className="counter-name">Supervisions</span>
            </div>
          </ScrollAnimationWrapper>
          <ScrollAnimationWrapper delay={100}>
            <div className="count-container">
              <span className="counter">11</span>
              <span className="counter-name">Carpenter Foreman</span>
            </div>
          </ScrollAnimationWrapper>
          <ScrollAnimationWrapper delay={150}>
            <div className="count-container">
              <span className="counter">10</span>
              <span className="counter-name">Fitter Foreman</span>
            </div>
          </ScrollAnimationWrapper>
          <ScrollAnimationWrapper delay={200}>
            <div className="count-container">
              <span className="counter">06</span>
              <span className="counter-name">Administrations</span>
            </div>
          </ScrollAnimationWrapper>
        </div>
      </TeamStrengthWrapper>
      <ManagerMembersWrapper>
        <ScrollAnimationWrapper animateIn="animate__fadeInLeft">
          <h2>Promotors & Managing Directors</h2>
        </ScrollAnimationWrapper>
        {managerMember.map((mem, index) => (
          <ManagersDetails
            key={index}
            name={mem.name}
            details={mem.details}
            image={mem.image}
          />
        ))}
      </ManagerMembersWrapper>
      <TeamMembersWrapper>
        <ScrollAnimationWrapper animateIn="animate__fadeInLeft">
          <h2>Team members</h2>
        </ScrollAnimationWrapper>
        <div className="members-container">
          {teamMembers.map((mem, index) => (
            <TeamMemberDetails
              key={index}
              name={mem.name}
              qualification={mem.qualification}
              designation={mem.designation}
              experience={mem.experience}
              present_involvement={mem.present_involvement}
            />
          ))}
        </div>
      </TeamMembersWrapper>
    </section>
  )
}

export default Teams
