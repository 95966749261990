import React, { useMemo } from 'react'
import { usePagination, useTable } from 'react-table'
import styled from 'styled-components'
import PreviousProjects from '../assets/json/previous_projects.json'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

const PreviousProjectsTableWrapper = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
  }

  tr {
    width: 100%;
  }

  td,
  th {
    border: 1px solid;
    padding: 10px 5px;
  }

  .pagination {
    display: flex;
    padding: 10px 0px;
    justify-content: flex-end;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 15px;
    }

    .page-size-dropdown {
      outline: none;
      padding: 5px 10px;
      border-color: var(--primary-color);
      border-radius: 3px;
      color: inherit;
      background: inherit;
    }

    .page-change-buttons {
      vertical-align: middle;
      text-align: center;
      font-size: 20px;
      cursor: pointer;
    }
  }
`

const PreviousProjectsTable = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Sr No.',
        accessor: 'sr_no'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Owner',
        accessor: 'owner'
      },
      {
        Header: 'Year',
        accessor: 'year'
      },
      {
        Header: 'Area',
        accessor: 'area'
      }
    ],
    []
  )

  const data = useMemo(() => PreviousProjects, [])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 }
    },
    usePagination
  )

  return (
    <PreviousProjectsTableWrapper>
      <div className="pagination">
        <select
          className="page-size-dropdown"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[5, 10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        <span
          className="page-change-buttons"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft />
        </span>
        <div>
          Page{' '}
          <em>
            {pageIndex + 1} of {pageOptions.length}
          </em>
        </div>
        <span
          className="page-change-buttons"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <FaAngleRight />
        </span>
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </PreviousProjectsTableWrapper>
  )
}

export default PreviousProjectsTable
