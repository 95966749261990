import React from 'react'
import styled from 'styled-components'
import ScrollAnimationWrapper from './ScrollAnimationWrapper'

const TeamMemberWrapper = styled.div`
  max-width: 45%;
  font-size: 15px;
  margin-bottom: 25px;

  &:hover {
    .name-qual-container h4 {
      font-size: 19px;
    }
  }

  .name-qual-container {
    font-size: 17px;
    h4 {
      transition: all 0.2s ease-in;
    }
  }

  .desig-exp-container {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
    padding-bottom: 3px;
    border-bottom: 0.5px solid;
  }

  .present-involvement {
    ul {
      list-style-position: inside;
    }
  }

  @media screen and (max-width: 600px) {
    max-width: unset;
  }
`

const TeamMemberDetails = ({
  name,
  qualification,
  designation,
  experience,
  present_involvement
}) => {
  return (
    <TeamMemberWrapper>
      <ScrollAnimationWrapper>
        <div className="name-qual-container">
          <h4>{name}</h4>
          <h6>{qualification}</h6>
        </div>
        <div className="desig-exp-container">
          <span>{designation}</span>
          <span>{experience}</span>
        </div>
        <div className="present-involvement">
          <span>Tasks: </span>
          <ul>
            {present_involvement.map((element, index) => (
              <li key={index}>{element}</li>
            ))}
          </ul>
        </div>
      </ScrollAnimationWrapper>
    </TeamMemberWrapper>
  )
}

export default TeamMemberDetails
