import React, { useCallback, useEffect, useState } from 'react'

const ThemeSwitcher = () => {
  const [path, setPath] = useState('')

  const changeColors = useCallback((p, pl, l, lt, d, dl, dt) => {
    document.documentElement.style.setProperty('--primary-color', `#${p}`)
    document.documentElement.style.setProperty(
      '--primary-color-light',
      `#${pl}`
    )
    document.documentElement.style.setProperty('--light-color', `#${l}`)
    document.documentElement.style.setProperty(
      '--light-color-transperent',
      `#${lt}`
    )
    document.documentElement.style.setProperty('--dark-color', `#${d}`)
    document.documentElement.style.setProperty('--dark-color-light', `#${dl}`)
    document.documentElement.style.setProperty(
      '--dark-color-transperent',
      `#${dt}`
    )
  }, [])

  const changeTheme = useCallback(
    themName => {
      const dl_offset = '15',
        dt_offset = '0a',
        lt_offset = '75'
      let p, pl, l, lt, d, dl, dt
      // d = 'FE5F55'
      switch (themName) {
        case 'ryb-green':
          p = '5FAD41'
          pl = '8CCA73'
          l = 'F3F9F0'
          lt = `${l}${lt_offset}`
          d = '14283E'
          dl = `${d}${dl_offset}`
          dt = `${d}${dt_offset}`
          break
        case 'ceris-pink':
          p = 'DA4167'
          pl = 'E7849D'
          l = 'FEF9FA'
          lt = `${l}${lt_offset}`
          d = '14283E'
          dl = `${d}${dl_offset}`
          dt = `${d}${dt_offset}`
          break
        case 'cryola-yellow':
          p = 'D29E0F'
          pl = 'F4D06F'
          l = 'FEF9EC'
          lt = `${l}${lt_offset}`
          d = '14283E'
          dl = `${d}${dl_offset}`
          dt = `${d}${dt_offset}`
          break
        case 'skobeloff-green':
          p = '216869'
          pl = '34A3A5'
          l = 'F3FAF6'
          lt = `${l}${lt_offset}`
          d = '1F2421'
          dl = `${d}${dl_offset}`
          dt = `${d}${dt_offset}`
          break
        case 'queen-blue':
          p = '376CA9'
          pl = '84ABD7'
          l = 'F7FBFE'
          lt = `${l}${lt_offset}`
          d = '0D160B'
          dl = `${d}${dl_offset}`
          dt = `${d}${dt_offset}`
          break
        case 'orange-modified':
          p = 'CF5C36'
          pl = 'F5917E'
          l = 'FBFAF9'
          lt = `${l}${lt_offset}`
          d = '050517'
          dl = `${d}${dl_offset}`
          dt = `${d}${dt_offset}`
          break
        case 'orange':
          p = 'ED3F1D'
          pl = 'F5917E'
          l = 'F3EFED'
          lt = `${l}${lt_offset}`
          d = '1A2432'
          dl = `${d}${dl_offset}`
          dt = `${d}${dt_offset}`
          break
        case 'sea-green':
          p = '00A6A6'
          pl = '00D9D9'
          l = 'F1F8FC'
          lt = `${l}${lt_offset}`
          d = '122C54'
          // d = '050505'
          dl = `${d}${dl_offset}`
          dt = `${d}${dt_offset}`
          break
        case 'violet':
          p = '814BB0'
          pl = 'A57DC7'
          l = 'F7F7FD'
          lt = `${l}${lt_offset}`
          d = '120B19'
          dl = `${d}${dl_offset}`
          dt = `${d}${dt_offset}`
          break
        default:
          p = 'CF5C36'
          pl = 'F5917E'
          l = 'EDE6E3'
          lt = `${l}${lt_offset}`
          d = '050517'
          dl = `${d}${dl_offset}`
          dt = `${d}${dt_offset}`
      }
      changeColors(p, pl, l, lt, d, dl, dt)
    },
    [changeColors]
  )

  useEffect(() => {
    setPath(String(window.location.pathname).replace(/\//g, ''))
  }, [setPath])

  const themeSwitcherHandler = e => {
    changeTheme(e.target.value)
  }
  return (
    <>
      {path === 'chooseThemes' && (
        <div style={{ position: 'fixed', top: '90px', zIndex: 100 }}>
          <select
            className="theme-switcher"
            style={{
              padding: '3px'
            }}
            onChange={themeSwitcherHandler}
          >
            <option value="ryb-green">Green (RYB)</option>
            <option value="sea-green">Green (sea)</option>
            <option value="skobeloff-green">Green (skobeloff)</option>
            <option value="ceris-pink">Pink (Ceris)</option>
            <option value="cryola-yellow">Yellow (Crayola)</option>
            <option value="queen-blue">Blue (Queen)</option>
            <option value="orange-modified">Orange (Bright)</option>
            <option value="orange">Orange</option>
            <option value="violet">Violet</option>
          </select>
        </div>
      )}
    </>
  )
}

export default ThemeSwitcher
