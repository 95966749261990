import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { FaBars, FaTimes } from 'react-icons/fa'
import LogoProvider from '../components/LogoProvider'

const NavBarContainer = styled.nav`
  position: sticky;
  top: 0px;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // padding: 0px 15px;
  box-shadow: #0000002e 0px 5px 35px -14px;
  background-color: var(--light-color);
  color: var(--primary-color);
  z-index: 50;

  &.hide-nav {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: slideOutUp;
  }

  $.show-nav {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: slideInDown;
  }

  .nav-logo-text {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 2px;
      margin-top: -8px;
      color: var(--dark-color);
    }
  }

  .nav-logo-container {
    display: flex;
    flex-direction: row;
    padding: 15px 30px;
    padding-right: 50px;
    background-color: var(--primary-color);
    color: var(--light-color);
    align-items: center;

    svg {
      width: 40px;
      height: auto;
      object-fit: contain;
      margin-right: 10px;

      .upper-half-logo {
        fill: var(--dark-color);
        stroke: var(--dark-color);
      }

      .lower-half-logo {
        fill: var(--light-color);
        stroke: var(--light-color);
      }
    }
  }

  .nav-link-container {
    display: flex;
    padding: 0 10px;
    align-self: flex-end;
  }

  .nav-link {
    text-decoration: none;
    padding: 20px 15px;
    color: var(--primary-color-light);
    transition: all 0.2s ease;
    cursor: pointer;
    border-bottom: 5px solid;
    border-color: #00000000;

    &.active {
      color: var(--primary-color);
      border-color: unset;
    }

    &:not(.active):hover {
      color: var(--primary-color);
    }
  }

  .nav-hamburger {
    display: none;
    position: relative;
    margin-right: 30px;
    font-size: 25px;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
  }

  .bars-show {
    opacity: 1;
  }

  .bars-hide {
    opacity: 0;
  }

  @media screen and (max-width: 716px) {
    & > * {
      z-index: 50;
    }

    .nav-logo-text h3 {
      margin-top: -5px;
    }

    .nav-hamburger {
      display: block;
    }

    .nav-link-container {
      position: absolute;
      flex-direction: column;
      top: 81px;
      left: 0;
      background: var(--light-color);
      width: 100%;
      z-index: 49;
      padding: unset;
      box-shadow: inset 0px 20px 35px -30px #0000002b;
      max-height: 0;
      transition: max-height 1s ease-in-out;
      overflow: hidden;

      .nav-link {
        border: none;
        text-align: center;
      }

      .nav-link.active {
        color: var(--light-color);
        background-color: var(--primary-color);
        box-shadow: inset 0px 0px 35px -10px #0000002b;
      }
    }

    .menu-show {
      // animation-name: fadeInDown;
      // animation-duration: 0.7s;
      max-height: 100vh;
      transition-duration: 1s;
    }

    .menu-hide {
      // animation-name: fadeOutUp;
      // animation-duration: 0.7s;
      // animation-fill-mode: forwards;
      transition-duration: 0.7s;

      .nav-link {
        pointer-events: none;
      }
    }
  }
`

export const ChangeHash = hashToBeChanged => {
  let hash = window.location.hash
  if (hash === '' || hash === null || hash === undefined) hash = '#home'
  hash = String(hash).replace('#', '')
  if (hash === hashToBeChanged)
    hashToBeChanged =
      hashToBeChanged.charAt(0).toUpperCase() + hashToBeChanged.slice(1)
  window.location.hash = hashToBeChanged
}

const Navbar = ({ activePage, setActivePage }) => {
  const [menuOpen, setMenuOpen] = useState(false) // for smaller screen devices only

  const menuList = useMemo(
    () => [
      { name: 'Home', target: 'home' },
      { name: 'About', target: 'about' },
      { name: 'Projects', target: 'project' },
      { name: 'Teams', target: 'teams' },
      { name: 'Contact', target: 'contact' }
    ],
    []
  )
  const LinkClickHandler = pageName => {
    ChangeHash(pageName)
    setMenuOpen(false)
  }
  const HashChangeHandler = useCallback(function () {
    let hash = window.location.hash
    const headOffset = 60
    if (hash === '' || hash === null || hash === undefined) hash = '#home'
    hash = String(hash).replace('#', '').toLowerCase()
    window.scrollTo({
      top: document.getElementById(`${hash}-container`).offsetTop - headOffset,
      behavior: 'smooth'
    })
    return hash
  }, [])

  useEffect(() => {
    window.addEventListener('hashchange', HashChangeHandler)
    HashChangeHandler()
    return () => window.removeEventListener('hashchange', HashChangeHandler)
  }, [HashChangeHandler, setActivePage])
  return (
    <NavBarContainer>
      <div className="nav-logo-container">
        <LogoProvider />
        <div className="nav-logo-text">
          <h1>Dhiara</h1>
          <h3>Group</h3>
        </div>
      </div>
      <div
        className="nav-hamburger"
        onClick={() => setMenuOpen(menuOpen => !menuOpen)}
      >
        {menuOpen ? (
          <FaTimes
            className={`nav-menu-icon ${menuOpen ? 'bars-show' : 'bars-hide'}`}
          />
        ) : (
          <FaBars
            className={`nav-menu-icon ${menuOpen ? 'bars-hide' : 'bars-show'}`}
          />
        )}
      </div>
      <div
        className={`nav-link-container ${menuOpen ? 'menu-show' : 'menu-hide'}`}
      >
        {menuList.map((item, index) => {
          return (
            <span
              key={`nav-link-item-${index}`}
              onClick={() => LinkClickHandler(item.target)}
              className={`nav-link ${
                activePage === item.target ? 'active' : ''
              }`}
            >
              {item.name}
            </span>
          )
        })}
      </div>
    </NavBarContainer>
  )
}

export default Navbar
